@import url("https://fonts.googleapis.com/css?family=Homemade+Apple|Indie+Flower|Permanent+Marker|Rock+Salt|Warnes|Lobster");

html,
body,
#root,
.App,
.App > div {
	height: 100%;
	width: 100%;
	overscroll-behavior: contain;
}

h1 {
	font-size: 32px;
	color: red;
}

.button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
    
	.color-swatch {
		z-index: 20;
		margin-bottom: 10;
	}
}

.fullscreen-mode {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2;
	right: 0;
	margin: 0 auto;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	button {
		flex: 1;
		height: 40px;
		color: white;
		font-size: 18px;
		font-weight: 800;
		border: none;
	}
	button:nth-child(1) {
		background: red;
	}
	button:nth-child(2) {
		background: rgb(132, 0, 255);
	}
}

.whiteboard {
	position: fixed;
	top: 0;
	left: 0;
	//cursor: url('./pencil.png'), crosshair;
}

.room-name {
	position: fixed;
	font-size: 32px;
	top: 10px;
	left: 0;
	right: 0;
	text-align: center;
	margin: 0 auto;
	//font-family: "Rock Salt", "Homemade Apple", cursive;
}

.clear {
	position: fixed;
    bottom: 5%;
    right: center;
    
	button {
		background: white;
		width: 25px;
		height: 25px;
		border: none;
		border-radius: 50%;
		border-width: 1px solid #000000;
		font-size: 13px;
		cursor: pointer;
		border:2px solid #000000;
		opacity: 0.3;
	}
}
.clearsmall{
	position: fixed;
	bottom:5%;
	right: 10%;
	button {
		background: white;
		width: 12px;
		height: 14px;
		border: none;
		border-radius: 50%;
		border-width: 1px solid #000000;
		font-size: 13px;
		cursor: pointer;
		border:2px solid #000000;
		opacity: 0.3;
	}
}
.backpress{
	position: fixed;
	bottom:5%;
	left: 10%;
	button {
		background: white;
		width: 25px;
		height: 25px;
		border: none;
		border-radius: 50%;
		border-width: 1px solid #000000;
		font-size: 13px;
		cursor: pointer;
		border:2px solid #000000;
		opacity: 0.3;
	}
}
.backpresssmall{
	position: fixed;
	bottom:5%;
	left: 10%;
	button {
		background: white;
		width: 12px;
		height: 14px;
		border: none;
		border-radius: 50%;
		border-width: 1px solid #000000;
		font-size: 13px;
		cursor: pointer;
		border:2px solid #000000;
		opacity: 0.3;
	}
}

.settingpress{
	position: fixed;
	bottom:5%;
	right: 10%;
	button {
		background: white;
		width: 25px;
		height: 25px;
		border: none;
		border-radius: 50%;
		border-width: 1px solid #000000;
		font-size: 13px;
		cursor: pointer;
		border:2px solid #000000;
		opacity: 0.3;
		
	}
}
.backpress button:focus {
	outline: none;
	box-shadow: none;
  }
.backpresssmall button:focus {
	outline: none;
	box-shadow: none;
  }
.settingpress button:focus {
	outline: none;
	box-shadow: none;
}
.clearsmall button:focus {
	outline: none;
	box-shadow: none;
}
.clear button:focus {
	outline: none;
	box-shadow: none;
}
.settingpage{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #403f3e;
	justify-content: center;
	align-items: center;
}
.rangeInput{
	// position: fixed;
	
	// top:50px;
	// left: 50px;
}
.iconDiv{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.input{
	margin-top:10px
}
.invite{
	
}
.setting_button{
	display: hidden;
	text-decoration: none ;
	color: #9a9b9c;
}
.setting_button:hover{
	text-decoration: none;
	color: #9a9b9c;
}
.btn-color{
	
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
}
.login {
	height: 100vh;
	width: 100vw;
	background: rgb(253, 253, 253);
	font-family: Lobster -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

	.warning-message {
		color: red;
	}

	.submit-container {
		justify-content: flex-start;
		padding-top: 5px;
	}

	> div {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		flex-direction: column;
		margin: 0 auto;

		width: 320px;
		> div {
			.input-container {
				width: 100%;
				input {
					margin: 10px 0;
					height: 40px;
					width: 100%;
					border: none;
					border-radius: 3px;
					font-size: 18px;
					font-weight: 600;
					padding-left: 10px;
					background: rgb(235, 235, 235);
					box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.308);
					outline: none;
				}
			}

			.logo-container {
				width: 300px;
				position: relative;

				.appname {
					position: absolute;
					font-size: 90px;
					transform: rotate(-15deg);
					top: 60px;
					font-family: "Lobster", cursive;
					text-shadow: 1px 1px 2px black;
				}

				.just {
					right: 60px;
					z-index: 3;
				}

				.drw {
					top: 130px;
				}

				.logo {
					width: 100%;
				}
			}

			> div {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				margin: 0 auto;
				width: 100%;

				.instructions {
					background: rgb(235, 235, 235);
					color: rgb(0, 0, 0);
					// box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.308);
					border: solid rgb(184, 184, 184) 3px;
					border-radius: 10px;
					height: 110px;
					width: 330px;
					h2 {
						font-size: 24px;
						padding: 0;
					}

					p {
						font-size: 16px;
					}
				}

				> div {
					padding: 0 5px;
					height: 65px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					button {
						width: 100px;
						margin: 0 auto;
						height: 40px;
						background: rgb(255, 66, 98);
						border-radius: 3px;
						box-shadow: 0px 5px 0px rgb(185, 37, 61);
						color: white;
						border: none;
						font-weight: 800;
						font-size: 18px;
						outline: none;
						cursor: pointer;
					}

					button:active {
						margin-top: 4px;
						box-shadow: 0px 1px 0px rgb(185, 37, 61);
					}
				}
			}
		}
	}
}

.user-list {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: fixed;
	top: 100px;
	right: 20px;
	//font-family: "Rock Salt", "Homemade Apple", cursive;

	span {
		margin: 5px 0px;
	}
}

// @media (display-mode: standalone) {
// 	.button-container {
// 		.fullscreen-mode {
// 			> button:first-child {
// 				display: none;
// 			}
// 		}
// 	}
// }
